import React from "react";
import { connect } from "react-redux";
import Img from "gatsby-image";
import { graphql } from "gatsby";

const Articolo = (props) => {
  const { data, langu } = props;

  let langua = "";
  if (langu === "es-CA") {
    langua = "fr";
  } else {
    langua = langu.substring(0, 2).toLowerCase();
  }

  return (
    <div className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-three-fifths is-offset-one-fifth">
            <div className="content">
              <p className="is-size-5">{data.post.data.Data}</p>
              <h1 className="title is-spaced is-4">
                {data.post.data[`Titolo_${langua}`]}
              </h1>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content" style={{ marginBottom: "1.5rem" }}>
              {data.post.data.Immagine.localFiles.length ? (
                <Img
                  fluid={
                    data.post.data.Immagine.localFiles[0].childImageSharp.fluid
                  }
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-three-fifths is-offset-one-fifth">
            <div className="content">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.post.data[`Testo_${langua}`],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    langu: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(Articolo);

export const articoloQuery = graphql`
  query articoloPageQuery($slug: String!) {
    post: airtableBlog(data: { slug: { eq: $slug } }) {
      data {
        Data
        Titolo_it
        Titolo_es
        Testo_en
        Testo_fr
        slug
        Testo_es
        Testo_it
        Titolo_fr
        Titolo_en
        Immagine {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
